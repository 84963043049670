<div class="be-partner-full-page">
  <div class="container">
    <div class="join-us-widget d-flex">
      <div class="heading">
        <img
          loading="lazy"
          class="sparkles"
          src="assets/images/partner/sparkles.png"
          alt="sparkles"
        />

        <h1>{{ "we-introduce-you-and-increase-your-sales" | translate }}</h1>
        <p>
          {{ "we-introduce-you-and-increase-your-sales-desc" | translate }}
        </p>
      </div>

      <div class="form">
        <h3>{{ "lets-get-started" | translate }}</h3>
        <p class="subtitle">
          {{ "fill-your-business-info" | translate }}
        </p>

        <form *ngIf="bePartnerForm" [formGroup]="bePartnerForm">
          <section>
            <p class="section-title">
              {{ "merchant-information" | translate }}
            </p>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  type="text"
                  class="form-widget-input"
                  [placeholder]="'merchant-name' | translate"
                  formControlName="merchantName"
                  [ngClass]="{
                  'invalid-input':
                    controls?.['merchantName']?.invalid &&
                    controls?.['merchantName']?.touched
                }"
                />
                <div class="error-space">
                  <div
                    *ngIf="
                    controls?.['merchantName']?.invalid &&
                    controls?.['merchantName']?.touched
                  "
                    class="error"
                  >
                    <span
                      *ngIf="controls?.['merchantName']?.errors?.['required']"
                    >
                      {{ "required" | translate }}
                    </span>
                    <span
                      *ngIf="controls?.['merchantName']?.errors?.['pattern']"
                    >
                      {{
                        "field-must-contains-only-letters-spaces-and-numbers"
                          | translate
                      }}
                    </span>
                    <span
                      *ngIf="controls?.['merchantName']?.errors?.['maxlength']"
                    >
                      {{ "max-length-is-error" | translate : { length: 25 } }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  type="text"
                  class="form-widget-input"
                  [placeholder]="'brand-name' | translate"
                  formControlName="brandName"
                  [ngClass]="{
                  'invalid-input':controls?.['brandName']?.invalid && controls?.['brandName']?.touched
                }"
                />

                <div class="error-space">
                  <div
                    *ngIf=" controls?.['brandName']?.invalid && controls?.['brandName']?.touched"
                    class="error"
                  >
                    <span *ngIf="controls?.['brandName']?.errors?.['required']">
                      {{ "required" | translate }}
                    </span>
                    <span *ngIf="controls?.['brandName']?.errors?.['pattern']">
                      {{
                        "field-should-contains-chars-spaces-and-special-chars"
                          | translate
                      }}
                    </span>
                    <span
                      *ngIf="controls?.['brandName']?.errors?.['maxlength']"
                    >
                      {{ "max-length-is-error" | translate : { length: 50 } }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  type="tel"
                  class="form-widget-input"
                  [placeholder]="'branches-number' | translate"
                  formControlName="numberOfBranches"
                  minlength="0"
                  maxlength="4"
                  [ngClass]="{
                  'invalid-input':
                    controls?.['numberOfBranches']?.invalid &&
                    controls?.['numberOfBranches']?.touched
                }"
                />
                <div class="error-space">
                  <div
                    *ngIf="
                    controls?.['numberOfBranches']?.invalid &&
                    controls?.['numberOfBranches']?.touched
                  "
                    class="error"
                  >
                    <span
                      *ngIf="controls?.['numberOfBranches']?.errors?.['required']"
                    >
                      {{ "required" | translate }}
                    </span>
                    <span
                      *ngIf="
                      controls?.['numberOfBranches']?.errors?.['min']
                    "
                    >
                      {{ "branches-number-is-should-be-numbers" | translate }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  type="text"
                  class="form-widget-input"
                  [placeholder]="'commercial-registration-number' | translate"
                  formControlName="commercialRegistrationNumber"
                  [ngClass]="{
                  'invalid-input':
                    controls?.['commercialRegistrationNumber']
                      ?.invalid &&
                    controls?.['commercialRegistrationNumber']
                      ?.touched
                }"
                />
                <div class="error-space">
                  <div
                    *ngIf="
                    controls?.['commercialRegistrationNumber']
                      ?.invalid &&
                    controls?.['commercialRegistrationNumber']
                      ?.touched
                  "
                    class="error"
                  >
                    <span
                      *ngIf="controls?.['commercialRegistrationNumber']?.errors?.['required']"
                    >
                      {{ "required" | translate }}
                    </span>
                    <span
                      *ngIf="controls?.['commercialRegistrationNumber']?.errors?.['pattern']"
                    >
                      {{ "field-alphanumeric-with-dashes" | translate }}
                    </span>
                    <span
                      *ngIf="controls?.['commercialRegistrationNumber']?.errors?.['maxlength']"
                    >
                      {{ "max-length-is-error" | translate : { length: 15 } }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <p class="section-title">
              {{ "store-rRepresentative-information" | translate }}
            </p>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  type="text"
                  class="form-widget-input"
                  [placeholder]="'relationship-to-store' | translate"
                  formControlName="relationshipToTheStore"
                  [ngClass]="{
                  'invalid-input':
                    controls?.['relationshipToTheStore']?.invalid &&
                    controls?.['relationshipToTheStore']?.touched
                }"
                />
                <div class="error-space">
                  <div
                    *ngIf="
                    controls?.['relationshipToTheStore']?.invalid &&
                    controls?.['relationshipToTheStore']?.touched
                  "
                    class="error"
                  >
                    <span
                      *ngIf="controls?.['relationshipToTheStore']?.errors?.['required']"
                    >
                      {{ "required" | translate }}
                    </span>
                    <span
                      *ngIf="controls?.['relationshipToTheStore']?.errors?.['pattern']"
                    >
                      {{
                        "field-should-contains-chars-spaces-and-special-chars"
                          | translate
                      }}
                    </span>
                    <span
                      *ngIf="controls?.['relationshipToTheStore']?.errors?.['maxlength']"
                    >
                      {{ "max-length-is-error" | translate : { length: 20 } }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  type="text"
                  class="form-widget-input"
                  [placeholder]="'name' | translate"
                  formControlName="name"
                  [ngClass]="{
                  'invalid-input':
                    controls?.['name']?.invalid &&
                    controls?.['name']?.touched
                }"
                />
                <div class="error-space">
                  <div
                    *ngIf="
                    controls?.['name']?.invalid &&
                    controls?.['name']?.touched
                  "
                    class="error"
                  >
                    <span *ngIf="controls?.['name']?.errors?.['required']">
                      {{ "required" | translate }}
                    </span>
                    <span *ngIf="controls?.['name']?.errors?.['pattern']">
                      {{
                        "field-must-contains-only-letters-spaces-and-numbers"
                          | translate
                      }}
                    </span>
                    <span *ngIf="controls?.['name']?.errors?.['maxlength']">
                      {{ "max-length-is-error" | translate : { length: 25 } }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  type="tel"
                  minlength="0"
                  maxlength="10"
                  class="form-widget-input"
                  [placeholder]="'id-number' | translate"
                  formControlName="idNumber"
                  [ngClass]="{
                  'invalid-input':
                    controls?.['idNumber']?.invalid &&
                    controls?.['idNumber']?.touched
                }"
                />
                <div class="error-space">
                  <div
                    *ngIf="
                    controls?.['idNumber']?.invalid &&
                    controls?.['idNumber']?.touched
                  "
                    class="error"
                  >
                    <span *ngIf="controls?.['idNumber']?.errors?.['required']">
                      {{ "required" | translate }}
                    </span>
                    <span *ngIf="controls?.['idNumber']?.errors?.['pattern']">
                      {{
                        "field-should-be-10-numbers-and-start-with-1-or-2"
                          | translate
                      }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  type="text"
                  datepicker
                  format="YYYY-MM-DD"
                  formControlName="dateOfBirth"
                  [placeholder]="'birth-date' | translate"
                  class="form-widget-input"
                  [ngClass]="{
                  'invalid-input':
                    controls?.['dateOfBirth']?.invalid &&
                    controls?.['dateOfBirth']?.touched
                }"
                />

                <div class="error-space">
                  <div
                    *ngIf="
                    controls?.['dateOfBirth']?.invalid &&
                    controls?.['dateOfBirth']?.touched
                  "
                    class="error"
                  >
                    <span
                      *ngIf="
                      controls?.['dateOfBirth']?.errors?.['required']
                    "
                    >
                      {{ "field-is-required" | translate }}
                    </span>
                    <span
                      *ngIf="
                      controls?.['dateOfBirth']?.errors?.['minimumAge']
                    "
                    >
                      {{ "age-should-be-18" | translate }}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content">
                <input
                  onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                  type="tel"
                  minlength="9"
                  maxlength="9"
                  class="form-widget-input"
                  [placeholder]="'phone' | translate"
                  formControlName="phoneNumber"
                  appLeadingZero
                  [ngClass]="{
                  'invalid-input':
                    controls?.['phoneNumber']?.invalid &&
                    controls?.['phoneNumber']?.touched
                }"
                />
                <div class="error-space">
                  <div
                    *ngIf="
                    controls?.['phoneNumber']?.invalid &&
                    controls?.['phoneNumber']?.touched
                  "
                    class="error"
                  >
                    <span
                      *ngIf="controls?.['phoneNumber']?.errors?.['required']"
                    >
                      {{ "required" | translate }}
                    </span>
                    <span
                      *ngIf="controls?.['phoneNumber']?.errors?.['pattern']"
                    >
                      {{ "phone-must-be-number" | translate }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section>
            <p class="section-title">
              {{ "documents-needed" | translate }}
            </p>

            <div class="form-widget">
              <div class="form-widget-content-half">
                <app-file-picker
                  [formGroup]="bePartnerForm"
                  fieldControl="commercialRegistration"
                >
                </app-file-picker>
              </div>
              <div class="form-widget-content-half">
                <app-file-picker
                  [formGroup]="bePartnerForm"
                  fieldControl="vatCertificate"
                >
                </app-file-picker>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content-half">
                <app-file-picker
                  [formGroup]="bePartnerForm"
                  fieldControl="merchantBankAccount"
                >
                </app-file-picker>
              </div>
              <div class="form-widget-content-half">
                <app-file-picker
                  [formGroup]="bePartnerForm"
                  fieldControl="signatoriesPersonalId"
                >
                </app-file-picker>
              </div>
            </div>

            <div class="form-widget">
              <div class="form-widget-content-half">
                <app-file-picker
                  [formGroup]="bePartnerForm"
                  fieldControl="municipalityLicense"
                >
                </app-file-picker>
              </div>
              <div class="form-widget-content-half">
                <app-file-picker
                  [formGroup]="bePartnerForm"
                  fieldControl="eCommerceAuthenticationCertificate"
                >
                </app-file-picker>
              </div>
            </div>
          </section>

          <button
            [disabled]="bePartnerForm.invalid || loading"
            class="be-partner-button full"
            type="button"
            (click)="bePartnerFormMethod()"
          >
            {{ "confirm" | translate }}
            <img
              loading="lazy"
              src="assets/images/baby-blue-arrow-right.svg"
              alt="arrow"
            />
          </button>
        </form>
      </div>
    </div>
  </div>
</div>

<app-be-partner-registration-completed
  *ngIf="showSuccessModal"
></app-be-partner-registration-completed>
