import {
  Component,
  ElementRef,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-file-picker',
  templateUrl: './file-picker.component.html',
  styleUrls: ['./file-picker.component.scss'],
})
export class FilePickerComponent implements OnChanges {
  @Input() formGroup!: FormGroup;
  @Input() fieldControl: string = '';
  @Input() isRequired: boolean = false;

  @ViewChild('filePickerInput') filePickerInput!: ElementRef;

  file = '';

  constructor() {}

  onFileSelected(event: any): void {
    const file: File = event.target.files[0];

    this.formGroup.patchValue({ [this.fieldControl]: file });
    this.formGroup.get(this.fieldControl)?.markAsTouched();

    if (file) {
      this.file = URL.createObjectURL(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
    }
  }

  ngOnChanges() {
    if (this.formGroup?.controls?.[this.fieldControl]?.value) {
      const file = this.formGroup.controls[this.fieldControl].value;

      this.file = this.file = URL.createObjectURL(file);
      const reader = new FileReader();
      reader.readAsDataURL(file);
    } else {
      this.file = '';
      if (this.filePickerInput?.nativeElement) {
        this.filePickerInput.nativeElement.value = '';
      }
    }
  }

  openFileInNewTab() {
    window.open(this.file, '_blank');
  }

  onRemoveFile(event: Event) {
    event.stopPropagation();

    this.removeFile();
  }

  removeFile() {
    this.filePickerInput.nativeElement.value = '';
    this.file = '';
    this.formGroup.patchValue({ [this.fieldControl]: null });
  }
}
