import { Component } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BePartnerService } from 'src/app/pages/partner/services/be-partner.service';
import { TokenInitService } from 'src/app/services/token-init.service';

@Component({
  selector: 'app-all-steps-in-one-partner-form',
  templateUrl: './be-partner-form-all-steps-in-one-form.component.html',
  styleUrls: ['./be-partner-form-all-steps-in-one-form.component.scss'],
})
export class AllStepsInOnePartnerFormComponent {
  bePartnerForm?: FormGroup;
  maxBirthDate: Date = new Date();
  loading = false;
  showSuccessModal = false;

  private readonly alphanumericRegex =
    '^[a-zA-Z0-9\u0621-\u064A\u0660-\u0669 !@#$%^&*()_+\\-=\\[\\]{};\'"\\|,.<>\\/?٪]*$]*$';
  private readonly arEnCharsWithSpecialCharsRegex =
    '^[a-zA-Z\u0600-\u065F\u066E-\u06FF !@#$%^&*()_+\\-=\\[\\]{};\'"\\|,.<>\\/?٪]*$';

  get controls() {
    return this.bePartnerForm?.controls;
  }

  constructor(
    private readonly bePartnerService: BePartnerService,
    private readonly toaster: ToastrService,
    private readonly translate: TranslateService,
    private readonly tokenInitService: TokenInitService
  ) {
    this.initForm();
    this.maxBirthDate.setFullYear(this.maxBirthDate.getFullYear() - 18);
  }

  initForm() {
    this.initiateBePartnerForm();
  }

  initiateBePartnerForm() {
    this.bePartnerForm = new FormGroup({
      merchantName: new FormControl('', [
        Validators.required,
        Validators.maxLength(25),
        Validators.pattern(this.alphanumericRegex),
      ]),
      brandName: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(this.arEnCharsWithSpecialCharsRegex),
      ]),
      numberOfBranches: new FormControl(null, [
        Validators.required,
        this.numericValidator,
      ]),
      commercialRegistrationNumber: new FormControl(null, [
        Validators.required,
        Validators.maxLength(15),
        Validators.pattern('^[a-zA-Z0-9-]*$'),
      ]),

      relationshipToTheStore: new FormControl('', [
        Validators.required,
        Validators.maxLength(20),
        Validators.pattern(
          '^[a-zA-Z\u0621-\u064A !@#$%^&*()_+\\-=\\[\\]{};\'"\\|,.<>\\/?٪]*$'
        ),
      ]),
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(25),
        Validators.pattern(
          '^[a-zA-Z0-9\u0621-\u064A\u0660-\u0669 !@#$%^&*()_+\\-=\\[\\]{};\'"\\|,.<>\\/?٪]*$'
        ),
      ]),
      idNumber: new FormControl('', [
        Validators.required,
        Validators.pattern('^[12][0-9]{9}$'),
      ]),
      dateOfBirth: new FormControl(null, [
        Validators.required,
        this.minimumAgeValidator(18),
      ]),
      phoneNumber: new FormControl('', [
        Validators.required,
        Validators.pattern('^5[0-9]{8}$'),
      ]),

      commercialRegistration: new FormControl(null, [Validators.required]),
      vatCertificate: new FormControl(null),
      merchantBankAccount: new FormControl(null, [Validators.required]),
      signatoriesPersonalId: new FormControl(null, [Validators.required]),
      municipalityLicense: new FormControl(null),
      eCommerceAuthenticationCertificate: new FormControl(null),
    });
  }

  resetForm() {
    this.bePartnerForm?.reset();
  }

  numericValidator(control: AbstractControl): ValidationErrors | null {
    const number = control.value;
    const numericRegex = /^[0-9]*$/;
    if (numericRegex.test(number)) {
      return null;
    }
    return { invalidNumber: true };
  }

  minimumAgeValidator(minAge: number) {
    return (control: AbstractControl): ValidationErrors | null => {
      const birthDate = new Date(control.value);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();

      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }

      return age >= minAge
        ? null
        : { minimumAge: { requiredAge: minAge, actualAge: age } };
    };
  }

  bePartnerFormMethod() {
    this.loading = true;

    this.tokenInitService.tokenInit().subscribe({
      next: (response: any) => {
        localStorage.setItem('token', response.data.token);

        this.bePartnerService
          .oldBePartner(this.bePartnerForm?.value)
          .subscribe({
            next: () => {
              this.handleSuccess();
            },
            error: (error) => {
              this.loading = false;
              this.handleError(error);
            },
          });
      },
    });
  }

  private handleSuccess() {
    this.loading = false;
    this.openSuccessModal();

    this.resetForm();
  }

  private handleError(error: any) {
    if (error?.error?.errors) {
      const errors = error.error.errors;
      Object.keys(errors).forEach((key) => {
        const errorMessage = `${key}: ${errors[key].join(', ')}`;
        this.toaster.error(
          errorMessage,
          this.translate.instant('validation-error')
        );
      });
    } else {
      const errorMessage = error?.error?.detail || error?.error?.message;

      if (errorMessage) {
        this.toaster.error(
          errorMessage,
          this.translate.instant('something-went-wrong')
        );
      }
    }
  }

  openSuccessModal() {
    this.showSuccessModal = true;
    document.body.classList.add('no-scroll');
  }
}
