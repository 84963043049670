import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-be-partner-registration-completed',
  templateUrl: './registration-completed.component.html',
  styleUrls: ['./registration-completed.component.scss'],
})
export class BePartnerRegistrationCompleted implements OnInit {
  constructor(protected translate: TranslateService, private router: Router) {}

  ngOnInit(): void {
    this.redirectToHome();
  }

  redirectToHome() {
    setTimeout(() => {
      document.body.classList.remove('no-scroll');
      this.router.navigate([`/${this.translate.currentLang}/home`]);
    }, 30000);
  }
}
