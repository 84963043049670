<div class="modal">
  <div class="modal-content">
    <img
      loading="lazy"
      class="be-partner-completed"
      src="assets/images/partner/be-partner-completed.png"
      alt="customer service"
    />

    <div class="content">
      <p class="title">
        {{ "be-partner-registered-successfully" | translate }}
      </p>

      <p class="subtitle">{{ "team-will-review-details" | translate }}</p>

      <a class="open-home" [routerLink]="['/', translate.currentLang, 'home']">
        {{ "you-will-be-redirected-to-home" | translate }}
      </a>
    </div>
  </div>
</div>
