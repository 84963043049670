import { Component } from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BePartnerService } from 'src/app/pages/partner/services/be-partner.service';
import { TokenInitService } from 'src/app/services/token-init.service';

@Component({
  selector: 'app-three-steps-partner-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class ThreeStepsPartnerFormComponent {
  merchantInformationForm?: FormGroup;
  storeRepresentativeInformationForm?: FormGroup;
  attachmentsForm?: FormGroup;
  step = 1;
  maxBirthDate: Date = new Date();
  loading = false;

  private readonly alphanumericRegex =
    '^[a-zA-Z0-9\u0621-\u064A\u0660-\u0669 !@#$%^&*()_+\\-=\\[\\]{};\'"\\|,.<>\\/?٪]*$]*$';
  private readonly arEnCharsWithSpecialCharsRegex =
    '^[a-zA-Z\u0600-\u065F\u066E-\u06FF !@#$%^&*()_+\\-=\\[\\]{};\'"\\|,.<>\\/?٪]*$';

  constructor(
    private readonly bePartnerService: BePartnerService,
    private readonly toaster: ToastrService,
    private readonly translate: TranslateService,
    private readonly tokenInitService: TokenInitService
  ) {
    this.initForm();
    this.maxBirthDate.setFullYear(this.maxBirthDate.getFullYear() - 18);
  }

  initForm() {
    this.initiateMerchantInformationForm();
    this.initStoreRepresentativeInformationForm();
    this.initFreelancerInformationForm();
  }

  initiateMerchantInformationForm() {
    this.merchantInformationForm = new FormGroup({
      merchantName: new FormControl('', [
        Validators.required,
        Validators.maxLength(25),
        Validators.pattern(this.alphanumericRegex),
      ]),
      brandName: new FormControl('', [
        Validators.required,
        Validators.maxLength(50),
        Validators.pattern(this.arEnCharsWithSpecialCharsRegex),
      ]),
      numberOfBranches: new FormControl(null, [
        Validators.required,
        this.numericValidator,
      ]),
      commercialRegistrationNumber: new FormControl(null, [
        Validators.required,
        Validators.maxLength(15),
        Validators.pattern('^[a-zA-Z0-9-]*$'),
      ]),
    });
  }

  initStoreRepresentativeInformationForm() {
    this.storeRepresentativeInformationForm = new FormGroup({
      relationshipToTheStore: new FormControl('', [
        Validators.required,
        Validators.maxLength(20),
        Validators.pattern(
          '^[a-zA-Z\u0621-\u064A !@#$%^&*()_+\\-=\\[\\]{};\'"\\|,.<>\\/?٪]*$'
        ),
      ]),
      name: new FormControl('', [
        Validators.required,
        Validators.maxLength(25),
        Validators.pattern(
          '^[a-zA-Z0-9\u0621-\u064A\u0660-\u0669 !@#$%^&*()_+\\-=\\[\\]{};\'"\\|,.<>\\/?٪]*$'
        ),
      ]),
      idNumber: new FormControl('', [
        Validators.required,
        Validators.pattern('^[12][0-9]{9}$'),
      ]),
      dateOfBirth: new FormControl(null, [
        Validators.required,
        this.minimumAgeValidator(18),
      ]),
      phoneNumber: new FormControl('', [
        Validators.required,
        Validators.pattern('^5[0-9]{8}$'),
      ]),
      freelancingDocumentNumber: new FormControl(''),
    });
  }

  initFreelancerInformationForm() {
    this.attachmentsForm = new FormGroup({
      commercialRegistration: new FormControl(null, [Validators.required]),
      vatCertificate: new FormControl(null),
      merchantBankAccount: new FormControl(null, [Validators.required]),
      signatoriesPersonalId: new FormControl(null, [Validators.required]),
      municipalityLicense: new FormControl(null),
      eCommerceAuthenticationCertificate: new FormControl(null),
      // freelancerDocument: new FormControl(null),
    });
  }

  resetForm() {
    this.merchantInformationForm?.reset();
    this.storeRepresentativeInformationForm?.reset();
    this.attachmentsForm?.reset();
  }

  numericValidator(control: AbstractControl): ValidationErrors | null {
    const number = control.value;
    const numericRegex = /^[0-9]*$/;
    if (numericRegex.test(number)) {
      return null;
    }
    return { invalidNumber: true };
  }

  minimumAgeValidator(minAge: number) {
    return (control: AbstractControl): ValidationErrors | null => {
      const birthDate = new Date(control.value);
      const today = new Date();
      let age = today.getFullYear() - birthDate.getFullYear();
      const monthDifference = today.getMonth() - birthDate.getMonth();

      if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < birthDate.getDate())
      ) {
        age--;
      }

      return age >= minAge
        ? null
        : { minimumAge: { requiredAge: minAge, actualAge: age } };
    };
  }

  nextButtonHandler() {
    this.step += 1;
  }

  backButtonHandler() {
    this.step -= 1;
  }

  bePartnerFormMethod() {
    this.loading = true;

    this.tokenInitService.tokenInit().subscribe({
      next: (response: any) => {
        localStorage.setItem('token', response.data.token);
        const data = {
          ...this.merchantInformationForm?.value,
          ...this.storeRepresentativeInformationForm?.value,
          ...this.attachmentsForm?.value,
        };

        this.bePartnerService.bePartner(data).subscribe({
          next: () => {
            this.handleSuccess();
          },
          error: (error) => {
            this.loading = false;
            this.handleError(error);
          },
        });
      },
    });
  }

  private handleSuccess() {
    this.step = 1;
    this.resetForm();
    this.loading = false;

    this.toaster.success(
      '',
      this.translate.instant('form-submitted-successfully')
    );
  }

  private handleError(error: any) {
    if (error.error?.errors) {
      const errors = error.error.errors;
      Object.keys(errors).forEach((key) => {
        const errorMessage = `${key}: ${errors[key].join(', ')}`;
        this.toaster.error(
          errorMessage,
          this.translate.instant('validation-error')
        );
      });
    } else {
      const errorMessage = error.error.detail || error.error.message;
      this.toaster.error(
        errorMessage,
        this.translate.instant('something-went-wrong')
      );
    }
  }
}
